import { useEffect } from 'react';
import { log } from '@hungryroot/o11y';
import { getApiOrigin } from '@hungryroot/rest';

export const API_ORIGIN_META_TAG_ID = 'api-origin';

export async function createApiOriginMetaTag() {
  if (process.env.PUBLIC_HR_ENV === 'production') {
    log('dev mode in production', {});
    // log and return!
    return;
  }
  const apiOrigin = await getApiOrigin();

  const meta = document.createElement('meta');
  meta.setAttribute('id', API_ORIGIN_META_TAG_ID);
  meta.setAttribute('name', 'api-origin');
  meta.setAttribute('content', apiOrigin);

  document.head.appendChild(meta);
}

export function ApiOriginMetaTag() {
  useEffect(() => {
    createApiOriginMetaTag();
  }, []);

  return null;
}
